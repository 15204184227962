/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect } from 'react';
import '../../VodafoneIdea.scss';
import VodafoneImg from '../../../../../../assets/images/vodafone.jpg';
import IdeaImg from '../../../../../../assets/images/idealogo.jpg';
import './NPLCCrfForm.css';
import { JsonToTable } from 'react-json-to-table';
import {
  useExcelJsonList,
  useLoadRomsFormFieldListData,
  useLoadFieldValues,
  useLoadCompanySeal,
} from '../../../../../../hooks/form.hooks';
import { loadImageBlob } from '../../../../../../utils/aws.util';
import { useAuthContext } from '../../../../../../context/auth.context';
import { TERMS_VERSION, getVersionTerms, encode } from '../../cafCrf.util';
import { Spinner, Center, useTheme } from '@chakra-ui/react';

const NPLCCRFForm = ({ formData, queueData, romsActivity, submittedForms }) => {
  const [CrfData, setCrfData] = useState(false);
  const [nplcCrfDataUpdated, setUpdatedCrfData] = useState(null);
  const [companySeal, setCompanySeal] = useState(null);
  const [photoGraph, setPhotograph] = useState(null);
  const [authorizedSignatoryName, setAuthSignName] = useState(null);
  const [imageData, setImageData] = useState(null);
  const [imageType, setImageType] = useState(null);
  const [acctMngSignImageData, setacctMngSignImageData] = useState(null);
  const [acctMngSignImageType, setacctMngSignImageType] = useState(null);
  const [photoGraphImageData, setphotoGraphImageData] = useState(null);
  const [photoGraphImageType, setphotoGraphImageType] = useState(null);
  const [excelJson, setexcelJson] = useState(null);

  const { mutate } = useLoadRomsFormFieldListData();
  const { mutate: loadOrderFormValues } = useLoadFieldValues();
  const { mutate: loadExcelJsonValues } = useExcelJsonList();
  const { mutate: loadCompanySeal } = useLoadCompanySeal();
  let componentRef = React.useRef();
  const chakraTheme = useTheme();

  const {
    state: { authData },
  } = useAuthContext();

  const fetchFieldListData = () => {
    mutate(
      {
        fieldList: {
          organization_id: authData.organization_id,
          account_id: authData.account_id,
          workforce_id: authData.workforce_id,
          form_id: formData.form_id,
        },
        fieldValues: {
          form_id: formData.form_id,
          form_transaction_id: formData.form_transaction_id
            ? formData.form_transaction_id
            : formData.data_form_transaction_id,
          start_from: 0,
          limit_value: 50,
        },
      },
      {
        onSuccess: async data => {
          let finalFields = data;
          setCrfData(false);
          setUpdatedCrfData(finalFields);
        },
        onError: async err => {
          console.log('error occurred while loading fields', err);
        },
      }
    );
  };

  const fetchOrderFormValues = () => {
    let orderForm = submittedForms.find(
      item => item.form_id === romsActivity.order_form_id
    );
    loadOrderFormValues(
      {
        form_id: romsActivity?.order_form_id,
        form_transaction_id: !!orderForm
          ? orderForm.data_form_transaction_id
          : 0,
      },
      {
        onSuccess: async res => {
          if (res.length > 0) {
            let ExcelIndex = res[0].activity_inline_data.findIndex(
              res1 => res1.field_id === 11748
            );
            let excelUrl;
            if (ExcelIndex > -1) {
              excelUrl = res[0].activity_inline_data[ExcelIndex].field_value;
            }
            if (!!excelUrl) {
              loadExcelJsonValues(
                { bucket_url: excelUrl },
                {
                  onSuccess: async result => {
                    setexcelJson(JSON.parse(result));
                  },
                }
              );
            }
          }
        },
      }
    );
  };

  const fetchCompanySeal = () => {
    loadCompanySeal(
      {
        form_id: 1330,
        field_id: 0,
        activity_id: queueData.activity_id,
      },
      {
        onSuccess: async res => {
          let companySeal = '';
          let authorizedSignatoryName = '';
          let data = [];
          if (res.length > 0) {
            data = JSON.parse(res[0].data_entity_inline).form_submitted;
          } else {
            data = [];
          }
          data.forEach(function (fieldObj, index) {
            if (fieldObj.field_id === 11751) {
              companySeal = fieldObj.field_value;
            }
            if (fieldObj.field_id === 11750) {
              authorizedSignatoryName = fieldObj.field_value;
            }
          });
          setCompanySeal(companySeal);
          setAuthSignName(authorizedSignatoryName);
        },
      }
    );
  };

  useEffect(() => {
    setCrfData(true);
    fetchCompanySeal();
    fetchFieldListData();
    fetchOrderFormValues();
  }, []);

  useEffect(() => {
    let data = nplcCrfDataUpdated;
    if (!!data) {
      if (!!companySeal) {
        // customer company seal
        let custCompanySeal = companySeal;
        loadImageBlob(custCompanySeal, (data, type) => {
          setImageData(data);
          setImageType(type);
        });
      }
      if (!!data[10264].value) {
        // manager sign url
        let acctManagerSignUrl = data[10264].value;
        loadImageBlob(acctManagerSignUrl, (data, type) => {
          setacctMngSignImageData(data);
          setacctMngSignImageType(type);
        });
      }
    }
  }, [nplcCrfDataUpdated, companySeal, photoGraph, authorizedSignatoryName]);

  let NplcCrfContentView = '';
  if (!!nplcCrfDataUpdated) {
    let firstCaps = '';
    let secondBold = '';
    let Signcss;
    let Overlapcss = 'signate_nplccrf_in float_img';

    if (!!authorizedSignatoryName) {
      let customerSignSplit = authorizedSignatoryName.split(' ');

      if (customerSignSplit.length > 1) {
        firstCaps = customerSignSplit['0'].slice(0, 1).toUpperCase();
        secondBold = customerSignSplit['1'];
        Signcss = 'signate_img input_sign';
      } else {
        firstCaps = customerSignSplit['0'];
        secondBold = '';
        Signcss = 'signate_img input_sign under_line';
      }
    }
    NplcCrfContentView = (
      <div>
        <div
          style={{ textAlign: 'left' }}
          className="tab_content"
          ref={el => (componentRef = el)}
        >
          <page size="A4">
            <div className="section2">
              <div className="font8">
                <p className="over_hid">
                  <img src={VodafoneImg} />
                  <img src={IdeaImg} className="pullr" />
                </p>
                <div className="pad_20t">
                  <div className="over_hid">
                    <div className="pd_t5">
                      {' '}
                      <span className="textcenter width70 fleft font15 mplhed">
                        NPLC Change Request Form
                      </span>
                      <div className="caf">
                        CAF ID
                        <input
                          className="form_col"
                          type="text"
                          name=""
                          value={nplcCrfDataUpdated[10092].value}
                          readOnly
                        />
                      </div>
                    </div>
                  </div>
                  <div className="over_hid mar_t-5">
                    <div className="pd_t5">
                      <strong>Company(Customer) Name</strong>{' '}
                      <span className="pad_l10">
                        <input
                          className="form_col_input"
                          type="text"
                          name=""
                          value={nplcCrfDataUpdated[10093].value}
                          readOnly
                        />
                      </span>
                      <div className="caf_inner">
                        <strong>Account Code</strong>
                        <input
                          className="form_col no_top"
                          type="text"
                          name=""
                          value={nplcCrfDataUpdated[10094].value}
                          readOnly
                        />
                      </div>
                    </div>
                  </div>
                  <p className="sign font12">
                    <strong>Authorised Signatory Details</strong>
                  </p>
                </div>
                <div className="over_hid auth">
                  <div className="fleft width45">
                    Name
                    <input
                      className="form_col width85"
                      type="text"
                      name=""
                      value={nplcCrfDataUpdated[10096].value}
                      readOnly
                    />
                  </div>
                  <div className="fleft width25">
                    Contact No:
                    <input
                      className="form_col width59"
                      type="text"
                      name=""
                      value={nplcCrfDataUpdated[10097].value}
                      readOnly
                    />
                  </div>
                  <div className="fleft width30">
                    Email:
                    <input
                      className="form_col width80"
                      type="text"
                      name=""
                      value={nplcCrfDataUpdated[10098].value}
                      readOnly
                    />
                  </div>
                </div>
                <div className="over_hid pad_t10">
                  <div className="fleft width45 textcenter">
                    {' '}
                    Feasibility ID (FR ID){' '}
                  </div>
                  <div className="fleft width45 textcenter">
                    <input
                      className="form_col width50"
                      type="text"
                      name=""
                      value={nplcCrfDataUpdated[10099].value}
                      readOnly
                    />
                  </div>
                </div>
                <div className="over_hid pad_5t">
                  <div className="fleft width45 textcenter">
                    {' '}
                    Please mention circuit id.
                  </div>
                  <div className="fleft width45 textcenter">
                    <input
                      className="form_col width50"
                      type="text"
                      name=""
                      value={nplcCrfDataUpdated[10100].value}
                      readOnly
                    />
                  </div>
                </div>
                <div className="over_hid pad_5t">
                  <div className="fleft width45 textcenter">
                    Please mention VOX ID
                  </div>
                  <div className="fleft width45 textcenter">
                    <input
                      className="form_col width50"
                      type="text"
                      name=""
                      value={nplcCrfDataUpdated[10101].value}
                      readOnly
                    />
                  </div>
                </div>
                <p className="margin0 font15">TYPE OF CHANGE REQUEST ORDER</p>
                <div className="over_hid pad_t15">
                  <div className="fleft width45 pad_l5per">
                    <div className="over_hid">
                      <div className="fleft width80 pad_t2">
                        {' '}
                        1 Company Name Change{' '}
                      </div>
                      <div className="fleft">
                        {nplcCrfDataUpdated[10102].value
                          .split('|')
                          .indexOf('Company Name Change') >= 0 ? (
                          <input
                            className="form_col width37px"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col width37px"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                      </div>
                    </div>
                    <div className="over_hid pad_t2">
                      <div className="fleft width80 pad_t2">
                        {' '}
                        2 Customer contact Change{' '}
                      </div>
                      <div className="fleft">
                        {nplcCrfDataUpdated[10102].value
                          .split('|')
                          .indexOf('Customer contact Change') >= 0 ? (
                          <input
                            className="form_col width37px"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col width37px"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                      </div>
                    </div>
                    <div className="over_hid pad_t2">
                      <div className="fleft width80 pad_t2">
                        {' '}
                        3 Billing Address change{' '}
                      </div>
                      <div className="fleft">
                        {nplcCrfDataUpdated[10102].value
                          .split('|')
                          .indexOf('Billing Address change') >= 0 ? (
                          <input
                            className="form_col check15"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col check15"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                      </div>
                    </div>
                    <div className="over_hid pad_t2">
                      <div className="fleft width80 pad_t2">
                        {' '}
                        4 Bandwidth Upgrade -same interface{' '}
                      </div>
                      <div className="fleft">
                        {nplcCrfDataUpdated[10102].value
                          .split('|')
                          .indexOf('Bandwidth Upgrade -same interface') >= 0 ? (
                          <input
                            className="form_col check15"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col check15"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                      </div>
                    </div>
                    <div className="over_hid pad_t2">
                      <div className="fleft width80 pad_t2">
                        {' '}
                        5 Bandwidth Upgrade with interface change{' '}
                      </div>
                      <div className="fleft">
                        {nplcCrfDataUpdated[10102].value
                          .split('|')
                          .indexOf('Bandwidth Upgrade with interface change') >=
                        0 ? (
                          <input
                            className="form_col check15"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col check15"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                      </div>
                    </div>
                    <div className="over_hid pad_t2">
                      <div className="fleft width80 pad_t2">
                        {' '}
                        6 Bandwidth Downgrade- Same Interface{' '}
                      </div>
                      <div className="fleft">
                        {nplcCrfDataUpdated[10102].value
                          .split('|')
                          .indexOf('Bandwidth Downgrade- Same Interface') >=
                        0 ? (
                          <input
                            className="form_col check15"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col check15"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                      </div>
                    </div>
                    <div className="over_hid pad_t2">
                      <div className="fleft width80 pad_t2">
                        {' '}
                        7 Bandwidth Downgrade- Interface change{' '}
                      </div>
                      <div className="fleft">
                        {nplcCrfDataUpdated[10102].value
                          .split('|')
                          .indexOf('Bandwidth Downgrade- Interface change') >=
                        0 ? (
                          <input
                            className="form_col check15"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col check15"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                      </div>
                    </div>
                    <div className="over_hid pad_t2">
                      <div className="fleft width80 pad_t2">
                        {' '}
                        8 Interface Change
                      </div>
                      <div className="fleft">
                        {nplcCrfDataUpdated[10102].value
                          .split('|')
                          .indexOf('Interface Change') >= 0 ? (
                          <input
                            className="form_col check15"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col check15"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                      </div>
                    </div>
                    <div className="over_hid pad_t2">
                      <div className="fleft width80 pad_t2">
                        {' '}
                        9 Interface Change- Media Change{' '}
                      </div>
                      <div className="fleft">
                        {nplcCrfDataUpdated[10102].value
                          .split('|')
                          .indexOf('Interface Change- Media Change') >= 0 ? (
                          <input
                            className="form_col check15"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col check15"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                      </div>
                    </div>
                    <div className="over_hid pad_t2">
                      <div className="fleft width80 pad_t2">
                        {' '}
                        10 Interface Change- LM provider change
                      </div>
                      <div className="fleft">
                        {nplcCrfDataUpdated[10102].value
                          .split('|')
                          .indexOf('Interface Change- LM provider change') >=
                        0 ? (
                          <input
                            className="form_col check15"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col check15"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="fleft width45 pad_l5per">
                    <div className="over_hid">
                      <div className="fleft width80 pad_t2">
                        {' '}
                        11 Change in CPE- Add/ Remove{' '}
                      </div>
                      <div className="fleft">
                        {nplcCrfDataUpdated[10102].value
                          .split('|')
                          .indexOf('Change in CPE- Add/ Remove') >= 0 ? (
                          <input
                            className="form_col check15"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col check15"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                      </div>
                    </div>
                    <div className="over_hid pad_t2">
                      <div className="fleft width80 pad_t2">
                        {' '}
                        12 Site Shifting
                      </div>
                      <div className="fleft">
                        {nplcCrfDataUpdated[10102].value
                          .split('|')
                          .indexOf('Site Shifting') >= 0 ? (
                          <input
                            className="form_col check15"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col check15"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                      </div>
                    </div>
                    <div className="over_hid pad_t2">
                      <div className="fleft width80 pad_t2">
                        {' '}
                        13 Billing Periodicity Change{' '}
                      </div>
                      <div className="fleft">
                        {nplcCrfDataUpdated[10102].value
                          .split('|')
                          .indexOf('Billing Periodicity Change') >= 0 ? (
                          <input
                            className="form_col check15"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col check15"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                      </div>
                    </div>
                    <div className="over_hid pad_t2">
                      <div className="fleft width80 pad_t2">
                        {' '}
                        14 Subscribe Managed Services{' '}
                      </div>
                      <div className="fleft">
                        {nplcCrfDataUpdated[10102].value
                          .split('|')
                          .indexOf('Subscribe Managed Services') >= 0 ? (
                          <input
                            className="form_col check15"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col check15"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                      </div>
                    </div>
                    <div className="over_hid pad_t2">
                      <div className="fleft width80 pad_t2">
                        {' '}
                        15 Unsubscribe Managed Services
                      </div>
                      <div className="fleft">
                        {nplcCrfDataUpdated[10102].value
                          .split('|')
                          .indexOf('Unsubscribe Managed Services') >= 0 ? (
                          <input
                            className="form_col check15"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col check15"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                      </div>
                    </div>
                    <div className="over_hid pad_t2">
                      <div className="fleft width80 pad_t2">
                        {' '}
                        16 Change of Managed Services Variant{' '}
                      </div>
                      <div className="fleft">
                        {nplcCrfDataUpdated[10102].value
                          .split('|')
                          .indexOf('Change of Managed Services Variant') >=
                        0 ? (
                          <input
                            className="form_col check15"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col check15"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                      </div>
                    </div>
                    <div className="over_hid pad_t2">
                      <div className="fleft width80 pad_t2">
                        {' '}
                        17 Change in SLA Type{' '}
                      </div>
                      <div className="fleft">
                        {nplcCrfDataUpdated[10102].value
                          .split('|')
                          .indexOf('Change in SLA Type') >= 0 ? (
                          <input
                            className="form_col check15"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col check15"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                      </div>
                    </div>
                    <div className="over_hid pad_t2">
                      <div className="fleft width80 pad_t2">
                        {' '}
                        18 Change in Self care service variant{' '}
                      </div>
                      <div className="fleft">
                        {nplcCrfDataUpdated[10102].value
                          .split('|')
                          .indexOf('Change in Self care service variant') >=
                        0 ? (
                          <input
                            className="form_col check15"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col check15"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                      </div>
                    </div>
                    <div className="over_hid pad_t2">
                      <div className="fleft width80 pad_t2">
                        {' '}
                        19 Revision in charges{' '}
                      </div>
                      <div className="fleft">
                        {nplcCrfDataUpdated[10102].value
                          .split('|')
                          .indexOf('Revision in charges') >= 0 ? (
                          <input
                            className="form_col check15"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col check15"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                      </div>
                    </div>
                    <div className="over_hid pad_t2">
                      <div className="fleft width80 pad_t2">
                        {' '}
                        20 Other Changes{' '}
                      </div>
                      <div className="fleft">
                        {nplcCrfDataUpdated[10102].value
                          .split('|')
                          .indexOf('Other Changes') >= 0 ? (
                          <input
                            className="form_col check15"
                            type="checkbox"
                            name=""
                            value=""
                            checked
                            readOnly
                          />
                        ) : (
                          <input
                            className="form_col check15"
                            type="checkbox"
                            name=""
                            value=""
                            disabled
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <p className="textcenter font15 margin0 pad_t10 pad_b5 hed5">
                  Company Name Change
                </p>
                <div className="check over_hid pad_t6">
                  <div className="fleft width170px pad_t3">
                    Existing Name of company
                  </div>
                  <div className="fleft width49">
                    <input
                      className="form_col widthfull"
                      type="text"
                      name=""
                      value={nplcCrfDataUpdated[10104].value}
                      readOnly
                    />
                  </div>
                </div>
                <div className="check over_hid pad_t6">
                  <div className="fleft width170px pad_t3">&nbsp;</div>
                  <div className="fleft width49">
                    <input
                      className="form_col widthfull"
                      type="text"
                      name=""
                      value=""
                    />
                  </div>
                </div>
                <div className="check over_hid pad_t6">
                  <div className="fleft width170px pad_t3">
                    New Name of company
                  </div>
                  <div className="fleft width49">
                    <input
                      className="form_col widthfull"
                      type="text"
                      name=""
                      value={nplcCrfDataUpdated[10105].value}
                      readOnly
                    />
                  </div>
                </div>
                <div className="check over_hid pad_t6">
                  <div className="fleft width170px pad_t3">&nbsp;</div>
                  <div className="fleft width49">
                    <input
                      className="form_col widthfull"
                      type="text"
                      name=""
                      value=""
                    />
                  </div>
                </div>
                <p className="textcenter font15 margin0 pad_t10 pad_b5 hed5">
                  Customer Contact Details Change
                </p>
                <div className="signate float_img">
                  {firstCaps !== '' ? (
                    <div style={{ padding: '10px' }} className={Signcss}>
                      {firstCaps + '  .  '}
                      <span className="under_line">{secondBold}</span>
                    </div>
                  ) : (
                    ''
                  )}
                  {imageData !== null && imageData !== '' ? (
                    <div style={{ padding: '10px' }} className="signate_img">
                      <img
                        src={
                          imageData != null
                            ? 'data:' +
                              imageType +
                              ';base64,' +
                              encode(imageData)
                            : ''
                        }
                        alt={'Company Seal'}
                      />
                    </div>
                  ) : (
                    ''
                  )}
                </div>
                <div className="check over_hid pad_t6">
                  <div className="fleft width170px pad_t3">
                    Existing telephone Number
                  </div>
                  <div className="fleft width49">
                    {' '}
                    <input
                      className="form_col widthfull"
                      type="text"
                      name=""
                      value={nplcCrfDataUpdated[10107].value}
                      readOnly
                    />
                  </div>
                </div>
                <div className="check over_hid pad_t6">
                  <div className="fleft width170px pad_t3">
                    New telephone Number
                  </div>
                  <div className="fleft width49">
                    <input
                      className="form_col widthfull"
                      type="text"
                      name=""
                      value={nplcCrfDataUpdated[10108].value}
                      readOnly
                    />
                  </div>
                </div>
                <div className="check over_hid pad_t6">
                  <div className="fleft width170px pad_t3">
                    Existing email address
                  </div>
                  <div className="fleft width49">
                    <input
                      className="form_col widthfull"
                      type="text"
                      name=""
                      value={nplcCrfDataUpdated[10109].value}
                      readOnly
                    />
                  </div>
                </div>
                <div className="check over_hid pad_t6">
                  <div className="fleft width170px pad_t3">
                    New email address
                  </div>
                  <div className="fleft width49">
                    <input
                      className="form_col widthfull"
                      type="text"
                      name=""
                      value={nplcCrfDataUpdated[10110].value}
                      readOnly
                    />
                  </div>
                </div>
              </div>
            </div>
          </page>
          <page size="A4">
            <div className="section2">
              <div className="font8">
                <p className="textcenter font15 margin0 pad_t10 pad_b5 hed5">
                  Billing Address Change
                </p>
                <p className="font12 margin0"> New Billing Address</p>
                <div className="check over_hid pad_t6">
                  <div className="fleft wid120p pad_t3">
                    Contact Person name
                  </div>
                  <div className="fleft width81">
                    <input
                      className="form_col widthfull"
                      type="text"
                      name=""
                      value={nplcCrfDataUpdated[10113].value}
                      readOnly
                    />
                  </div>
                </div>
                <div className="over_hid pad_t15">
                  <div className="fleft wid50">
                    <div className="check over_hid ">
                      <div className="fleft wid120p pad_t3">Designation</div>
                      <div className="fleft width63">
                        <input
                          className="form_col widthfull"
                          type="text"
                          name=""
                          value={nplcCrfDataUpdated[10114].value}
                          readOnly
                        />
                      </div>
                    </div>
                  </div>
                  <div className="fleft wid50">
                    <div className="check over_hid ">
                      <div className="fleft wid120p pad_t3 textcenter">
                        <span className="">Dept. Name</span>
                      </div>
                      <div className="fleft width63">
                        <input
                          className="form_col widthfull"
                          type="text"
                          name=""
                          value={nplcCrfDataUpdated[10115].value}
                          readOnly
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="check over_hid pad_t6">
                  <div className="fleft wid120p pad_t3">Address</div>
                  <div className="fleft width81">
                    <input
                      className="form_col widthfull"
                      type="text"
                      name=""
                      value={nplcCrfDataUpdated[10116].value}
                      readOnly
                    />
                  </div>
                </div>
                <div className="check over_hid pad_t6">
                  <div className="fleft wid120p pad_t3"> &nbsp; </div>
                  <div className="fleft width81">
                    <input
                      className="form_col widthfull"
                      type="text"
                      name=""
                      value=""
                    />
                  </div>
                </div>
                <div className="over_hid pad_t15">
                  <div className="fleft wid50">
                    <div className="check over_hid ">
                      <div className="fleft wid120p pad_t3">
                        City / Village / Post Office
                      </div>
                      <div className="fleft width63">
                        <input
                          className="form_col widthfull"
                          type="text"
                          name=""
                          value={nplcCrfDataUpdated[10117].value}
                          readOnly
                        />
                      </div>
                    </div>
                  </div>
                  <div className="fleft wid25">
                    <div className="check over_hid ">
                      <div className="fleft pad_t3 textcenter pad_l10 pad_r5">
                        <span className="">Pin</span>
                      </div>
                      <div className="fleft width63">
                        <input
                          className="form_col widthfull"
                          type="text"
                          name=""
                          value={nplcCrfDataUpdated[10118].value}
                          readOnly
                        />
                      </div>
                    </div>
                  </div>
                  <div className="fleft wid25">
                    <div className="check over_hid ">
                      <div className="fleft pad_t3 textcenter pad_l10 pad_r5">
                        <span className="">Telephone</span>
                      </div>
                      <div className="fleft width63">
                        <input
                          className="form_col width90"
                          type="text"
                          name=""
                          value={nplcCrfDataUpdated[10119].value}
                          readOnly
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="auth margin0 over_hid ">
                  <div className="fleft wid120p pad_t3 text_r">
                    Landmark &nbsp; &nbsp;{' '}
                  </div>
                  <div className="fleft width63">
                    <input
                      className="form_col widthfull"
                      type="text"
                      name=""
                      value={nplcCrfDataUpdated[10120].value}
                      readOnly
                    />
                  </div>
                </div>
                <p className="textcenter font15 margin0 pad_t10 pad_b5 hed5">
                  Site Shifting Installation Address Change
                </p>
                <div className="check over_hid pad_t6">
                  <div className="fleft font12 wid150p pad_t3">
                    Old Installation Address
                  </div>
                  <div className="fleft width78 pad_l10">
                    <input
                      className="form_col widthfull"
                      type="text"
                      name=""
                      value={nplcCrfDataUpdated[10122].value}
                      readOnly
                    />
                  </div>
                </div>
                <p className="font12"> New Installation Address</p>
                <div className="check over_hid pad_t6">
                  <div className="fleft wid120p pad_t3">
                    Contact Person name
                  </div>
                  <div className="fleft width81">
                    <input
                      className="form_col widthfull"
                      type="text"
                      name=""
                      value={nplcCrfDataUpdated[10124].value}
                      readOnly
                    />
                  </div>
                </div>
                <div className="over_hid pad_t15">
                  <div className="fleft wid50">
                    <div className="check over_hid ">
                      <div className="fleft wid120p pad_t3">Designation</div>
                      <div className="fleft width63">
                        <input
                          className="form_col widthfull"
                          type="text"
                          name=""
                          value={nplcCrfDataUpdated[10125].value}
                          readOnly
                        />
                      </div>
                    </div>
                  </div>
                  <div className="fleft wid50">
                    <div className="check over_hid ">
                      <div className="fleft wid120p pad_t3 textcenter">
                        <span className="">Dept. Name</span>
                      </div>
                      <div className="fleft width63">
                        <input
                          className="form_col widthfull"
                          type="text"
                          name=""
                          value={nplcCrfDataUpdated[10126].value}
                          readOnly
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="check over_hid pad_t6">
                  <div className="fleft wid120p pad_t3">Address</div>
                  <div className="fleft width81">
                    <input
                      className="form_col widthfull"
                      type="text"
                      name=""
                      value={nplcCrfDataUpdated[10127].value}
                      readOnly
                    />
                  </div>
                </div>
                <div className="check over_hid pad_t6">
                  <div className="fleft wid120p pad_t3"> &nbsp; </div>
                  <div className="fleft width81">
                    <input
                      className="form_col widthfull"
                      type="text"
                      name=""
                      value=""
                    />
                  </div>
                </div>
                <div className="over_hid pad_t15">
                  <div className="fleft wid50">
                    <div className="check over_hid ">
                      <div className="fleft wid120p pad_t3">
                        City / Village / Post Office
                      </div>
                      <div className="fleft width63">
                        <input
                          className="form_col widthfull"
                          type="text"
                          name=""
                          value={nplcCrfDataUpdated[10128].value}
                          readOnly
                        />
                      </div>
                    </div>
                  </div>
                  <div className="fleft wid25">
                    <div className="check over_hid ">
                      <div className="fleft pad_t3 textcenter pad_l10 pad_r5">
                        <span className="">Pin</span>
                      </div>
                      <div className="fleft width63">
                        <input
                          className="form_col widthfull"
                          type="text"
                          name=""
                          value={nplcCrfDataUpdated[10129].value}
                          readOnly
                        />
                      </div>
                    </div>
                  </div>
                  <div className="fleft wid25">
                    <div className="check over_hid ">
                      <div className="fleft pad_t3 textcenter pad_l10 pad_r5">
                        <span className="">Telephone</span>
                      </div>
                      <div className="fleft width63">
                        <input
                          className="form_col width90"
                          type="text"
                          name=""
                          value={nplcCrfDataUpdated[10130].value}
                          readOnly
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="auth margin0 over_hid ">
                  <div className="fleft wid120p pad_t3 text_r">
                    Landmark &nbsp; &nbsp;{' '}
                  </div>
                  <div className="fleft width63">
                    <input
                      className="form_col widthfull"
                      type="text"
                      name=""
                      value={nplcCrfDataUpdated[10131].value}
                      readOnly
                    />
                  </div>
                </div>
                <p className="textcenter font15 margin0 pad_t10 pad_b5 hed5">
                  Bandwidth Upgrade - Same Interface
                </p>
                <div className="pad_t10">
                  <div className="check over_hid">
                    <div className="fleft width130px pad_t3">
                      {' '}
                      Existing Bandwidth{' '}
                    </div>
                    <div className="fleft width30 ">
                      <input
                        className="form_col widthfull"
                        type="text"
                        name=""
                        value={nplcCrfDataUpdated[10133].value}
                        readOnly
                      />
                      Kbps / Mbps
                    </div>
                    <div className="fleft width130px pad_t3">
                      {' '}
                      New Bandwidth{' '}
                    </div>
                    <div className="fleft width30">
                      <input
                        className="form_col widthfull"
                        type="text"
                        name=""
                        value={nplcCrfDataUpdated[10134].value}
                        readOnly
                      />
                      Kbps / Mbps
                    </div>
                  </div>
                </div>
                <p className="textcenter font15 margin0 pad_t10 pad_b5 hed5">
                  Bandwidth Downgrade - Same Interface
                </p>
                <div className="pad_t10">
                  <div className="check over_hid">
                    <div className="fleft width130px pad_t3">
                      {' '}
                      Existing Bandwidth{' '}
                    </div>
                    <div className="fleft width30">
                      <input
                        className="form_col widthfull"
                        type="text"
                        name=""
                        value={nplcCrfDataUpdated[10136].value}
                        readOnly
                      />
                      Kbps / Mbps
                    </div>
                    <div className="fleft width130px pad_t3">
                      {' '}
                      New Bandwidth{' '}
                    </div>
                    <div className="fleft width30">
                      <input
                        className="form_col widthfull"
                        type="text"
                        name=""
                        value={nplcCrfDataUpdated[10137].value}
                        readOnly
                      />
                      Kbps / Mbps
                    </div>
                  </div>
                </div>
                <p className="textcenter font15 margin0 pad_t10 pad_b5 hed5">
                  Bandwidth Upgrade with Interface / LM / Media Change
                </p>
                <div className="pad_t10">
                  <div className="check over_hid">
                    <div className="fleft width130px pad_t3">
                      {' '}
                      Existing Bandwidth{' '}
                    </div>
                    <div className="fleft width20">
                      <input
                        className="form_col width90"
                        type="text"
                        name=""
                        value={nplcCrfDataUpdated[10139].value}
                        readOnly
                      />
                    </div>
                    <div className="fleft width130px pad_t3">
                      {' '}
                      New Bandwidth{' '}
                    </div>
                    <div className="fleft width20">
                      <input
                        className="form_col width90"
                        type="text"
                        name=""
                        value={nplcCrfDataUpdated[10140].value}
                        readOnly
                      />
                    </div>
                  </div>
                </div>
                <div className="pad_5t">
                  <div className="check over_hid">
                    <div className="fleft width130px pad_t3">
                      Existing Interface{' '}
                    </div>
                    <div className="fleft pad_r25">
                      {nplcCrfDataUpdated[10141].value === 'V.35' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      V.35
                    </div>
                    <div className="fleft pad_r25">
                      {nplcCrfDataUpdated[10141].value === 'G.703' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      G.703
                    </div>
                    <div className="fleft pad_r25">
                      {nplcCrfDataUpdated[10141].value ===
                      'Ethernet Electrical' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Ethernet Electrical
                    </div>
                    <div className="fleft pad_r25">
                      {nplcCrfDataUpdated[10141].value === 'BNC' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      BNC
                    </div>
                    <div className="fleft pad_r25">
                      {nplcCrfDataUpdated[10141].value ===
                      'Ethernet Optical' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Ethernet Optical
                    </div>
                    <div className="fleft pad_r25">
                      {nplcCrfDataUpdated[10141].value === 'Optical' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Optical
                    </div>
                  </div>
                </div>
                <div className="pad_5t">
                  <div className="check over_hid">
                    <div className="fleft width130px pad_t3">
                      New Interface{' '}
                    </div>
                    <div className="fleft pad_r25">
                      {nplcCrfDataUpdated[10142].value === 'V.35' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      V.35
                    </div>
                    <div className="fleft pad_r25">
                      {nplcCrfDataUpdated[10142].value === 'G.703' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      G.703
                    </div>
                    <div className="fleft pad_r25">
                      {nplcCrfDataUpdated[10142].value ===
                      'Ethernet Electrical' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Ethernet Electrical
                    </div>
                    <div className="fleft pad_r25">
                      {nplcCrfDataUpdated[10142].value === 'BNC' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      BNC
                    </div>
                    <div className="fleft pad_r25">
                      {nplcCrfDataUpdated[10142].value ===
                      'Ethernet Optical' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Ethernet Optical
                    </div>
                    <div className="fleft pad_r25">
                      {nplcCrfDataUpdated[10142].value === 'Optical' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Optical
                    </div>
                  </div>
                </div>
                <div className="pad_5t">
                  <div className="check gstcheck over_hid">
                    <div className="fleft pad_r25 pad_t3">
                      Interface Change with Change in Media{' '}
                    </div>
                    <div className="fleft pad_r25">
                      {' '}
                      <span className="pad_r5">Existing Media</span>
                      {nplcCrfDataUpdated[10143].value === 'Copper' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Copper
                    </div>
                    <div className="fleft pad_r25">
                      {nplcCrfDataUpdated[10143].value === 'Fiber' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Fiber
                    </div>
                    <div className="fleft pad_r25">
                      {nplcCrfDataUpdated[10143].value === 'UBR' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      UBR
                    </div>
                    <div className="fleft pad_r5">
                      {nplcCrfDataUpdated[10143].value === 'Other' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Other
                    </div>
                    <div className="fleft width12">
                      <input
                        className="form_col oth_gst"
                        type="text"
                        name=""
                        value=""
                        readOnly
                      />
                    </div>
                  </div>
                </div>
                <div className="pad_5t">
                  <div className="check gstcheck over_hid">
                    <div className="fleft pad_r25 w192px pad_t3">&nbsp;</div>
                    <div className="fleft pad_r25">
                      {' '}
                      <span className="pad_r5">New Media</span>
                      {nplcCrfDataUpdated[10144].value === 'Copper' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Copper
                    </div>
                    <div className="fleft pad_r25">
                      {nplcCrfDataUpdated[10144].value === 'Fiber' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Fiber
                    </div>
                    <div className="fleft pad_r25">
                      {nplcCrfDataUpdated[10144].value === 'UBR' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      UBR
                    </div>
                    <div className="fleft pad_r5">
                      {nplcCrfDataUpdated[10144].value === 'Other' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Other
                    </div>
                    <div className="fleft width12">
                      <input
                        className="form_col oth_gst"
                        type="text"
                        name=""
                        value=""
                        readOnly
                      />
                    </div>
                  </div>
                </div>
                <div className="pad_5t">
                  <div className="check gstcheck over_hid">
                    <div className="fleft pad_r25 pad_t3">
                      Interface Change with Change in Last Mile provider
                    </div>
                    <div className="fleft wid36">
                      {' '}
                      <span className="pad_5t fleft pad_r5">
                        Existing LM Provider{' '}
                      </span>
                      <input
                        className="form_col width56im"
                        type="text"
                        name=""
                        value={nplcCrfDataUpdated[10146].value}
                        readOnly
                      />
                    </div>
                    <div className="fleft wid25">
                      {' '}
                      <span className="pad_5t fleft pad_r5">
                        New LM Provider
                      </span>
                      <input
                        className="form_col width47im"
                        type="text"
                        name=""
                        value={nplcCrfDataUpdated[10147].value}
                        readOnly
                      />
                    </div>
                  </div>
                </div>
                <p className="textcenter font15 margin0 pad_t10 pad_b5 hed5">
                  Bandwidth Downgrade with Interface / LM / Media Change
                </p>
                <div className="pad_t10">
                  <div className="check over_hid">
                    <div className="fleft width130px pad_t3">
                      {' '}
                      Existing Bandwidth{' '}
                    </div>
                    <div className="fleft width20">
                      <input
                        className="form_col width47im"
                        type="text"
                        name=""
                        value={nplcCrfDataUpdated[10149].value}
                        readOnly
                      />
                    </div>
                    <div className="fleft width130px pad_t3">
                      {' '}
                      New Bandwidth{' '}
                    </div>
                    <div className="fleft width20">
                      <input
                        className="form_col width47im"
                        type="text"
                        name=""
                        value={nplcCrfDataUpdated[10150].value}
                        readOnly
                      />
                    </div>
                  </div>
                </div>
                <div className={Overlapcss}>
                  {firstCaps !== '' ? (
                    <div style={{ padding: '10px' }} className={Signcss}>
                      {firstCaps + '  .  '}
                      <span className="under_line">{secondBold}</span>
                    </div>
                  ) : (
                    ''
                  )}
                  {imageData !== null && imageData !== '' ? (
                    <div style={{ padding: '10px' }} className="signate_img">
                      <img
                        src={
                          imageData != null
                            ? 'data:' +
                              imageType +
                              ';base64,' +
                              encode(imageData)
                            : ''
                        }
                        alt={'Company Seal'}
                      />
                    </div>
                  ) : (
                    ''
                  )}
                </div>
                <div className="pad_5t">
                  <div className="check over_hid">
                    <div className="fleft width130px pad_t3">
                      Existing Interface{' '}
                    </div>
                    <div className="fleft pad_r25">
                      {nplcCrfDataUpdated[10151].value === 'V.35' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      V.35
                    </div>
                    <div className="fleft pad_r25">
                      {nplcCrfDataUpdated[10151].value === 'G.703' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      G.703
                    </div>
                    <div className="fleft pad_r25">
                      {nplcCrfDataUpdated[10151].value ===
                      'Ethernet Electrical' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Ethernet Electrical
                    </div>
                    <div className="fleft pad_r25">
                      {nplcCrfDataUpdated[10151].value === 'BNC' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      BNC
                    </div>
                    <div className="fleft pad_r25">
                      {nplcCrfDataUpdated[10151].value ===
                      'Ethernet Optical' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Ethernet Optical
                    </div>
                    <div className="fleft pad_r25">
                      {nplcCrfDataUpdated[10151].value === 'Optical' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Optical
                    </div>
                  </div>
                </div>
                <div className="pad_5t">
                  <div className="check over_hid">
                    <div className="fleft width130px pad_t3">
                      New Interface{' '}
                    </div>
                    <div className="fleft pad_r25">
                      {nplcCrfDataUpdated[10152].value === 'V.35' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      V.35
                    </div>
                    <div className="fleft pad_r25">
                      {nplcCrfDataUpdated[10152].value === 'G.703' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      G.703
                    </div>
                    <div className="fleft pad_r25">
                      {nplcCrfDataUpdated[10152].value ===
                      'Ethernet Electrical' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Ethernet Electrical
                    </div>
                    <div className="fleft pad_r25">
                      {nplcCrfDataUpdated[10152].value === 'BNC' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      BNC
                    </div>
                    <div className="fleft pad_r25">
                      {nplcCrfDataUpdated[10152].value ===
                      'Ethernet Optical' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Ethernet Optical
                    </div>
                    <div className="fleft pad_r25">
                      {nplcCrfDataUpdated[10152].value === 'Optical' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Optical
                    </div>
                  </div>
                </div>
                <div className="pad_5t">
                  <div className="check gstcheck over_hid">
                    <div className="fleft pad_r25 pad_t3">
                      Interface Change with Change in Media{' '}
                    </div>
                    <div className="fleft pad_r25">
                      {' '}
                      <span className="pad_r5">Existing Media</span>
                      {nplcCrfDataUpdated[10153].value === 'Copper' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Copper
                    </div>
                    <div className="fleft pad_r25">
                      {nplcCrfDataUpdated[10153].value === 'Fiber' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Fiber
                    </div>
                    <div className="fleft pad_r25">
                      {nplcCrfDataUpdated[10153].value === 'UBR' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      UBR
                    </div>
                    <div className="fleft pad_r5">
                      {nplcCrfDataUpdated[10153].value === 'Other' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Other
                    </div>
                    <div className="fleft width12">
                      <input
                        className="form_col oth_gst"
                        type="text"
                        name=""
                        value=""
                        readOnly
                      />
                    </div>
                  </div>
                </div>
                <div className="pad_5t">
                  <div className="check gstcheck over_hid">
                    <div className="fleft pad_r25 w192px pad_t3">&nbsp;</div>
                    <div className="fleft pad_r25">
                      {' '}
                      <span className="pad_r5">New Media</span>
                      {nplcCrfDataUpdated[10154].value === 'Copper' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Copper
                    </div>
                    <div className="fleft pad_r25">
                      {nplcCrfDataUpdated[10154].value === 'Fiber' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Fiber
                    </div>
                    <div className="fleft pad_r25">
                      {nplcCrfDataUpdated[10154].value === 'UBR' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      UBR
                    </div>
                    <div className="fleft pad_r5">
                      {nplcCrfDataUpdated[10154].value === 'Other' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Other
                    </div>
                    <div className="fleft width12">
                      <input
                        className="form_col oth_gst"
                        type="text"
                        name=""
                        value=""
                        readOnly
                      />
                    </div>
                  </div>
                </div>
                <div className="pad_5t">
                  <div className="check gstcheck over_hid">
                    <div className="fleft pad_r25 pad_t3">
                      Interface Change with Change in Last Mile provider
                    </div>
                    <div className="fleft wid36">
                      {' '}
                      <span className="pad_5t fleft pad_r5">
                        Existing LM Provider{' '}
                      </span>
                      <input
                        className="form_col width56im"
                        type="text"
                        name=""
                        value={nplcCrfDataUpdated[10156].value}
                        readOnly
                      />
                    </div>
                    <div className="fleft wid25">
                      {' '}
                      <span className="pad_5t fleft pad_r5">
                        New LM Provider
                      </span>
                      <input
                        className="form_col width47im"
                        type="text"
                        name=""
                        value={nplcCrfDataUpdated[10157].value}
                        readOnly
                      />
                    </div>
                  </div>
                </div>
                <br />
                <br />
              </div>
            </div>
          </page>

          <page size="A4">
            <div className="section2">
              <div className="font8">
                <p className="textcenter font15 margin0 pad_t10 pad_b5 hed5">
                  Interface Change{' '}
                </p>
                <div className="pad_t10">
                  <div className="check over_hid">
                    <div className="fleft width130px pad_t3">
                      Existing Interface{' '}
                    </div>
                    <div className="fleft pad_r25">
                      {nplcCrfDataUpdated[10159].value === 'V.35' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      V.35
                    </div>
                    <div className="fleft pad_r25">
                      {nplcCrfDataUpdated[10159].value === 'G.703' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      G.703
                    </div>
                    <div className="fleft pad_r25">
                      {nplcCrfDataUpdated[10159].value ===
                      'Ethernet Electrical' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Ethernet Electrical
                    </div>
                    <div className="fleft pad_r25">
                      {nplcCrfDataUpdated[10159].value === 'BNC' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      BNC
                    </div>
                    <div className="fleft pad_r25">
                      {nplcCrfDataUpdated[10159].value ===
                      'Ethernet Optical' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Ethernet Optical
                    </div>
                    <div className="fleft pad_r25">
                      {nplcCrfDataUpdated[10159].value === 'Optical' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Optical
                    </div>
                  </div>
                </div>
                <div className="pad_5t">
                  <div className="check over_hid">
                    <div className="fleft width130px pad_t3">
                      New Interface{' '}
                    </div>
                    <div className="fleft pad_r25">
                      {nplcCrfDataUpdated[10160].value === 'V.35' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      V.35
                    </div>
                    <div className="fleft pad_r25">
                      {nplcCrfDataUpdated[10160].value === 'G.703' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      G.703
                    </div>
                    <div className="fleft pad_r25">
                      {nplcCrfDataUpdated[10160].value ===
                      'Ethernet Electrical' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Ethernet Electrical
                    </div>
                    <div className="fleft pad_r25">
                      {nplcCrfDataUpdated[10160].value === 'BNC' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      BNC
                    </div>
                    <div className="fleft pad_r25">
                      {nplcCrfDataUpdated[10160].value ===
                      'Ethernet Optical' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Ethernet Optical
                    </div>
                    <div className="fleft pad_r25">
                      {nplcCrfDataUpdated[10160].value === 'Optical' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Optical
                    </div>
                  </div>
                </div>
                <p className="textcenter font15 margin0 pad_t10 pad_b5 hed5">
                  Interface Change with Media / LM Change
                </p>
                <div className="pad_t10">
                  <div className="check gstcheck over_hid">
                    <div className="fleft width130px pad_t3">
                      Existing Interface{' '}
                    </div>
                    <div className="fleft pad_r25">
                      {nplcCrfDataUpdated[10162].value === 'V.35' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      V.35
                    </div>
                    <div className="fleft pad_r15">
                      {nplcCrfDataUpdated[10162].value === 'G.703' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      G.703
                    </div>
                    <div className="fleft pad_r15">
                      {nplcCrfDataUpdated[10162].value ===
                      'Ethernet Electrical' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Ethernet Electrical
                    </div>
                    <div className="fleft pad_r15">
                      {nplcCrfDataUpdated[10162].value === 'BNC' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      BNC
                    </div>
                    <div className="fleft pad_r15">
                      {nplcCrfDataUpdated[10162].value ===
                      'Ethernet Optical' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Ethernet Optical
                    </div>
                    <div className="fleft pad_r15">
                      {nplcCrfDataUpdated[10162].value === 'Optical' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Optical
                    </div>
                    <div className="fleft pad_r15">
                      {nplcCrfDataUpdated[10162].value === 'Other' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Other
                    </div>
                    <div className="fleft width12">
                      <input
                        className="form_col oth_gst"
                        type="text"
                        name=""
                        value=""
                        readOnly
                      />
                    </div>
                  </div>
                </div>
                <div className="pad_5t">
                  <div className="check gstcheck over_hid">
                    <div className="fleft width130px pad_t3">
                      New Interface{' '}
                    </div>
                    <div className="fleft pad_r25">
                      {nplcCrfDataUpdated[10163].value === 'V.35' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      V.35
                    </div>
                    <div className="fleft pad_r15">
                      {nplcCrfDataUpdated[10163].value === 'G.703' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      G.703
                    </div>
                    <div className="fleft pad_r15">
                      {nplcCrfDataUpdated[10163].value ===
                      'Ethernet Electrical' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Ethernet Electrical
                    </div>
                    <div className="fleft pad_r15">
                      {nplcCrfDataUpdated[10163].value === 'BNC' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      BNC
                    </div>
                    <div className="fleft pad_r15">
                      {nplcCrfDataUpdated[10163].value ===
                      'Ethernet Optical' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Ethernet Optical
                    </div>
                    <div className="fleft pad_r15">
                      {nplcCrfDataUpdated[10163].value === 'Optical' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Optical
                    </div>
                    <div className="fleft pad_r15">
                      {nplcCrfDataUpdated[10163].value === 'Other' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Other
                    </div>
                    <div className="fleft width12">
                      <input
                        className="form_col oth_gst"
                        type="text"
                        name=""
                        value=""
                        readOnly
                      />
                    </div>
                  </div>
                </div>
                <div className="pad_5t">
                  <div className="check gstcheck over_hid">
                    <div className="fleft pad_r25 pad_t3">
                      Interface Change with Change in Media{' '}
                    </div>
                    <div className="fleft pad_r25">
                      {' '}
                      <span className="pad_r5">Existing Media</span>
                      {nplcCrfDataUpdated[10164].value === 'Copper' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Copper
                    </div>
                    <div className="fleft pad_r25">
                      {nplcCrfDataUpdated[10164].value === 'Fiber' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Fiber
                    </div>
                    <div className="fleft pad_r25">
                      {nplcCrfDataUpdated[10164].value === 'UBR' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      UBR
                    </div>
                    <div className="fleft pad_r5">
                      {nplcCrfDataUpdated[10164].value === 'Other' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Other
                    </div>
                    <div className="fleft width12">
                      <input
                        className="form_col oth_gst"
                        type="text"
                        name=""
                        value=""
                        readOnly
                      />
                    </div>
                  </div>
                </div>
                <div className="pad_5t">
                  <div className="check gstcheck over_hid">
                    <div className="fleft pad_r25 w192px pad_t3">&nbsp;</div>
                    <div className="fleft pad_r25">
                      {' '}
                      <span className="pad_r5">New Media</span>
                      {nplcCrfDataUpdated[10165].value === 'Copper' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Copper
                    </div>
                    <div className="fleft pad_r25">
                      {nplcCrfDataUpdated[10165].value === 'Fiber' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Fiber
                    </div>
                    <div className="fleft pad_r25">
                      {nplcCrfDataUpdated[10165].value === 'UBR' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      UBR
                    </div>
                    <div className="fleft pad_r5">
                      {nplcCrfDataUpdated[10165].value === 'Other' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Other
                    </div>
                    <div className="fleft width12">
                      <input
                        className="form_col oth_gst"
                        type="text"
                        name=""
                        value=""
                        readOnly
                      />
                    </div>
                  </div>
                </div>
                <div className="pad_5t">
                  <div className="check gstcheck over_hid">
                    <div className="fleft pad_r25 pad_t3">
                      Interface Change with Change in Last Mile provider
                    </div>
                    <div className="fleft wid36">
                      {' '}
                      <span className="pad_5t fleft pad_r5">
                        Existing LM Provider{' '}
                      </span>
                      <input
                        className="form_col width56im"
                        type="text"
                        name=""
                        value={nplcCrfDataUpdated[10167].value}
                        readOnly
                      />
                    </div>
                    <div className="fleft wid25">
                      {' '}
                      <span className="pad_5t fleft pad_r5">
                        New LM Provider
                      </span>
                      <input
                        className="form_col width47im"
                        type="text"
                        name=""
                        value={nplcCrfDataUpdated[10168].value}
                        readOnly
                      />
                    </div>
                  </div>
                </div>

                <p className="textcenter font15 margin0 pad_t10 pad_b5 hed5">
                  Addition of CPE
                </p>
                <div className="domestictable">
                  <div className="center_70per">
                    {' '}
                    <table
                      width="100%"
                      border="0"
                      cellSpacing="0"
                      cellPadding="0"
                    >
                      <tbody>
                        <tr>
                          <td>Sr. No.</td>
                          <td>Type (Standard/Other)</td>
                          <td>Make</td>
                          <td>Model</td>
                          <td>Version</td>
                        </tr>
                        <tr>
                          <td>1</td>
                          <td>{nplcCrfDataUpdated[10171].value}</td>
                          <td>{nplcCrfDataUpdated[10172].value}</td>
                          <td>{nplcCrfDataUpdated[10173].value}</td>
                          <td>{nplcCrfDataUpdated[10174].value}</td>
                        </tr>
                        <tr>
                          <td>2</td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                        </tr>
                        <tr>
                          <td>3</td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                        </tr>
                        <tr>
                          <td>4</td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                        </tr>
                        <tr>
                          <td>5</td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                        </tr>
                      </tbody>
                    </table>{' '}
                  </div>
                </div>
                <p className="textcenter font15 margin0 pad_t10 pad_b5 hed5">
                  Deletion of CPE
                </p>
                <div className="domestictable">
                  <div className="center_70per">
                    {' '}
                    <table
                      width="100%"
                      border="0"
                      cellSpacing="0"
                      cellPadding="0"
                    >
                      <tbody>
                        <tr>
                          <td>Sr. No.</td>
                          <td>Type (Standard/Other)</td>
                          <td>Make</td>
                          <td>Model</td>
                          <td>Version</td>
                        </tr>
                        <tr>
                          <td>1</td>
                          <td>{nplcCrfDataUpdated[10176].value}</td>
                          <td>{nplcCrfDataUpdated[10177].value}</td>
                          <td>{nplcCrfDataUpdated[10178].value}</td>
                          <td>{nplcCrfDataUpdated[10179].value}</td>
                        </tr>
                        <tr>
                          <td>2</td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                        </tr>
                        <tr>
                          <td>3</td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                        </tr>
                        <tr>
                          <td>4</td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                        </tr>
                        <tr>
                          <td>5</td>
                          <td>&nbsp;</td>
                          <td>&nbsp;</td>
                          <td>&nbsp;</td>
                          <td>&nbsp;</td>
                        </tr>
                      </tbody>
                    </table>{' '}
                  </div>
                </div>
                <p className="textcenter font15 margin0 pad_t10 pad_b5 hed5">
                  Billing Periodicity Change
                </p>
                <div className="pad_t5">
                  <div className="check over_hid pd_t5">
                    <div className="fleft pad_t3 width20">
                      {' '}
                      Existing Billing Periodicity{' '}
                    </div>
                    <div className="fleft width16">
                      {nplcCrfDataUpdated[10181].value === 'Monthly' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Monthly
                    </div>
                    <div className="fleft width16">
                      {nplcCrfDataUpdated[10181].value === 'Bi-Monthly' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Bi-Monthly
                    </div>
                    <div className="fleft width16">
                      {nplcCrfDataUpdated[10181].value === 'Quarterly' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Quarterly
                    </div>
                    <div className="fleft width16">
                      {nplcCrfDataUpdated[10181].value === 'Half Yearly' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Half Yearly
                    </div>
                    <div className="fleft width16">
                      {nplcCrfDataUpdated[10181].value === 'Annualy' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Annualy
                    </div>
                  </div>
                </div>
                <div className="pad_t5">
                  <div className="check over_hid pd_t5">
                    <div className="fleft pad_t3 width20">
                      Revised Billing Periodicity{' '}
                    </div>
                    <div className="fleft width16">
                      {nplcCrfDataUpdated[10182].value === 'Monthly' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Monthly
                    </div>
                    <div className="fleft width16">
                      {nplcCrfDataUpdated[10182].value === 'Bi-Monthly' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Bi-Monthly
                    </div>
                    <div className="fleft width16">
                      {nplcCrfDataUpdated[10182].value === 'Quarterly' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Quarterly
                    </div>
                    <div className="fleft width16">
                      {nplcCrfDataUpdated[10182].value === 'Half Yearly' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Half Yearly
                    </div>
                    <div className="fleft width16">
                      {nplcCrfDataUpdated[10182].value === 'Annualy' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Annualy
                    </div>
                  </div>
                </div>
                <p className="textcenter font15 margin0 pad_t10 pad_b5 hed5">
                  Subscribe Managed Services
                </p>
                <div className="pad_t5">
                  <div className="check over_hid pd_t5">
                    <div className="fleft pad_t3 width20">
                      {' '}
                      Managed services required{' '}
                    </div>
                    <div className="fleft width16">
                      {nplcCrfDataUpdated[10184].value === 'Yes' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Yes
                    </div>
                  </div>
                </div>
                <div className="pad_t5">
                  <div className="check over_hid pd_t5">
                    <div className="fleft pad_t3 width20 text_r pad_r5">
                      {' '}
                      Managed Service Variants{' '}
                    </div>
                    <div className="fleft pad_r25">
                      {nplcCrfDataUpdated[10185].value === 'Standard' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Standard
                    </div>
                    <div className="fleft pad_r25">
                      {nplcCrfDataUpdated[10185].value === 'Silver' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Silver
                    </div>

                    <div className="fleft pad_r25">
                      {nplcCrfDataUpdated[10185].value === 'Gold' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Gold
                    </div>

                    <div className="fleft pad_r25">
                      {nplcCrfDataUpdated[10185].value === 'Platinum' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Platinum
                    </div>
                  </div>
                </div>
                <p className="textcenter font15 margin0 pad_t10 pad_b5 hed5">
                  Un Subscribe Managed Services
                </p>
                <div className="pad_t5">
                  <div className="check over_hid pd_t5">
                    <div className="fleft pad_t3 width20">
                      {' '}
                      Managed services required{' '}
                    </div>
                    <div className="fleft width16">
                      {nplcCrfDataUpdated[10187].value === 'No' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      No
                    </div>
                  </div>
                </div>
                <p className="textcenter font15 margin0 pad_t10 pad_b5 hed5">
                  Change of Managed Services Variant
                </p>
                <div className="pad_t5">
                  <div className="check over_hid pd_t5">
                    <div className="fleft pad_t3 width22">
                      {' '}
                      Existing Managed Service Variants{' '}
                    </div>
                    <div className="fleft pad_r25">
                      {nplcCrfDataUpdated[10189].value === 'Standard' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Standard
                    </div>
                    <div className="fleft pad_r25">
                      {nplcCrfDataUpdated[10189].value === 'Silver' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Silver
                    </div>

                    <div className="fleft pad_r25">
                      {nplcCrfDataUpdated[10189].value === 'Gold' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Gold
                    </div>

                    <div className="fleft pad_r25">
                      {nplcCrfDataUpdated[10189].value === 'Platinum' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Platinum
                    </div>
                  </div>
                </div>
                <div className="pad_t5">
                  <div className="check over_hid pd_t5">
                    <div className="fleft pad_t3 width22">
                      {' '}
                      New Managed Service Variants{' '}
                    </div>
                    <div className="fleft pad_r25">
                      {nplcCrfDataUpdated[10190].value === 'Standard' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Standard
                    </div>
                    <div className="fleft pad_r25">
                      {nplcCrfDataUpdated[10190].value === 'Silver' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Silver
                    </div>

                    <div className="fleft pad_r25">
                      {nplcCrfDataUpdated[10190].value === 'Gold' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Gold
                    </div>

                    <div className="fleft pad_r25">
                      {nplcCrfDataUpdated[10190].value === 'Platinum' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Platinum
                    </div>
                  </div>
                </div>
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <div className="signate float_img">
                  {firstCaps !== '' ? (
                    <div style={{ padding: '10px' }} className={Signcss}>
                      {firstCaps + '  .  '}
                      <span className="under_line">{secondBold}</span>
                    </div>
                  ) : (
                    ''
                  )}
                  {imageData != null && imageData !== '' ? (
                    <div style={{ padding: '10px' }} className="signate_img">
                      <img
                        src={
                          imageData != null
                            ? 'data:' +
                              imageType +
                              ';base64,' +
                              encode(imageData)
                            : ''
                        }
                        alt={'Company Seal'}
                      />
                    </div>
                  ) : (
                    ''
                  )}
                </div>
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
              </div>
            </div>
          </page>

          <page size="A4">
            <div className="section2">
              <div className="font8">
                <p className="textcenter font15 margin0 pad_t10 pad_b5 hed5">
                  Change in SLA Type
                </p>
                <div className="pad_t5">
                  <div className="check over_hid pd_t5">
                    <div className="fleft width15 pad_t3">
                      {' '}
                      Existing SLA Type{' '}
                    </div>
                    <div className="fleft width16">
                      {nplcCrfDataUpdated[10192].value === 'Standard' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Standard
                    </div>
                    <div className="fleft width16">
                      {nplcCrfDataUpdated[10192].value === 'Customized' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Customized
                    </div>
                    <div className="fleft width16 pad_5t"> New SLA Type</div>
                    <div className="fleft width16">
                      {nplcCrfDataUpdated[10193].value === 'Standard' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Standard
                    </div>
                    <div className="fleft width16">
                      {nplcCrfDataUpdated[10193].value === 'Customized' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Customized
                    </div>
                  </div>
                </div>
                <div className="pad_t5">
                  <div className="check over_hid pd_t5">
                    <div className="fleft width20 pad_7t textcenter  pad_r5">
                      If SLA Type Customized{' '}
                    </div>
                    <div className="fleft width16 pad_r20">
                      Service Availability (%)
                      <input
                        className="form_col widthfull"
                        type="text"
                        name=""
                        value={nplcCrfDataUpdated[10195].value}
                        readOnly
                      />
                    </div>
                    <div className="fleft width12 pad_r20">
                      MTTR (Hours)
                      <input
                        className="form_col widthfull"
                        type="text"
                        name=""
                        value={nplcCrfDataUpdated[10196].value}
                        readOnly
                      />
                    </div>
                    <div className="fleft width12 pad_r20">
                      Latency (ms)
                      <input
                        className="form_col widthfull"
                        type="text"
                        name=""
                        value={nplcCrfDataUpdated[10197].value}
                        readOnly
                      />
                    </div>
                  </div>
                </div>
                <p className="textcenter font15 margin0 pad_t10 pad_b5 hed5">
                  Change in Self care service variant
                </p>
                <div className="pad_t5">
                  <div className="check over_hid pd_t5">
                    <div className="fleft pad_t3 width22">
                      {' '}
                      Existing Self Care Service Variant{' '}
                    </div>
                    <div className="fleft pad_r25">
                      {nplcCrfDataUpdated[10199].value === 'None' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      None
                    </div>
                    <div className="fleft pad_r25">
                      {nplcCrfDataUpdated[10199].value === 'Silver' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Silver
                    </div>
                    <div className="fleft pad_r25">
                      {nplcCrfDataUpdated[10199].value === 'Silver Plus' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Silver Plus
                    </div>
                    <div className="fleft pad_r25">
                      {nplcCrfDataUpdated[10199].value === 'Gold' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Gold
                    </div>
                    <div className="fleft pad_r25">
                      {nplcCrfDataUpdated[10199].value === 'Gold Plus' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Gold Plus
                    </div>
                  </div>
                </div>
                <div className="pad_t5">
                  <div className="check over_hid pd_t5">
                    <div className="fleft pad_t3 width22">
                      {' '}
                      New Self Care Service Variant{' '}
                    </div>
                    <div className="fleft pad_r25">
                      {nplcCrfDataUpdated[10200].value === 'None' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      None
                    </div>
                    <div className="fleft pad_r25">
                      {nplcCrfDataUpdated[10200].value === 'Silver' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Silver
                    </div>
                    <div className="fleft pad_r25">
                      {nplcCrfDataUpdated[10200].value === 'Silver Plus' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Silver Plus
                    </div>
                    <div className="fleft pad_r25">
                      {nplcCrfDataUpdated[10200].value === 'Gold' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Gold
                    </div>
                    <div className="fleft pad_r25">
                      {nplcCrfDataUpdated[10200].value === 'Gold Plus' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Gold Plus
                    </div>
                  </div>
                </div>
                <p className="textcenter font15 margin0 pad_t10 pad_b5 hed5">
                  Revision in Charges
                </p>
                <p>
                  (Only do required changes. Leave others blank if no change)
                </p>
                <div className="char">
                  <div className="check over_hid pad_t5">
                    <div className="fleft width170px pad_t3">
                      {' '}
                      Specify Currency{' '}
                    </div>
                    <div className="fleft width7">
                      {nplcCrfDataUpdated[10203].value === 'INR' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      INR{' '}
                    </div>
                    <div className="fleft width7">
                      {nplcCrfDataUpdated[10203].value === 'US$' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          checked
                          readOnly
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      US${' '}
                    </div>
                    <div className="fleft width15 textcenter">
                      {' '}
                      One Time(A){' '}
                    </div>
                    <div className="fleft width15 textcenter">
                      {' '}
                      Recurring (B){' '}
                    </div>
                    <div className="fleft width15 textcenter">
                      {' '}
                      Security Deposit ©{' '}
                    </div>
                    <div className="fleft width15 textcenter">
                      {' '}
                      Grand Total (A+B+C){' '}
                    </div>
                  </div>
                  <div className="check over_hid">
                    <div className="fleft width170px pad_t3">
                      {' '}
                      Service Rental
                    </div>
                    <div className="fleft width7">&nbsp; </div>
                    <div className="fleft width7"> &nbsp;</div>
                    <div className="fleft width15 textcenter">
                      <input
                        className="form_col width75"
                        type="text"
                        name=""
                        value={nplcCrfDataUpdated[10204].value}
                        readOnly
                      />
                    </div>
                    <div className="fleft width15 textcenter">
                      <input
                        className="form_col width75"
                        type="text"
                        name=""
                        value={nplcCrfDataUpdated[10205].value}
                        readOnly
                      />
                    </div>
                    <div className="fleft width15 textcenter">
                      <input
                        className="form_col width75"
                        type="text"
                        name=""
                        value={nplcCrfDataUpdated[10206].value}
                        readOnly
                      />
                    </div>
                    <div className="fleft width15 textcenter">
                      <input
                        className="form_col width75"
                        type="text"
                        name=""
                        value={nplcCrfDataUpdated[10207].value}
                        readOnly
                      />
                    </div>
                  </div>

                  <div className="check over_hid pad_t7">
                    <div className="fleft width170px pad_t3"> SLA Charges </div>
                    <div className="fleft width7">&nbsp; </div>
                    <div className="fleft width7"> &nbsp;</div>
                    <div className="fleft width15 textcenter">
                      <input
                        className="form_col width75"
                        type="text"
                        name=""
                        value={nplcCrfDataUpdated[10208].value}
                        readOnly
                      />
                    </div>
                    <div className="fleft width15 textcenter">
                      <input
                        className="form_col width75"
                        type="text"
                        name=""
                        value={nplcCrfDataUpdated[10209].value}
                        readOnly
                      />
                    </div>
                    <div className="fleft width15 textcenter"> &nbsp; </div>
                    <div className="fleft width15 textcenter">
                      <input
                        className="form_col width75"
                        type="text"
                        name=""
                        value={nplcCrfDataUpdated[10210].value}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="check over_hid pad_t7">
                    <div className="fleft width170px pad_t3">
                      Self Care Portal Service Charges
                    </div>
                    <div className="fleft width7">&nbsp; </div>
                    <div className="fleft width7"> &nbsp;</div>
                    <div className="fleft width15 textcenter">
                      <input
                        className="form_col width75"
                        type="text"
                        name=""
                        value={nplcCrfDataUpdated[10211].value}
                        readOnly
                      />
                    </div>
                    <div className="fleft width15 textcenter">
                      <input
                        className="form_col width75"
                        type="text"
                        name=""
                        value={nplcCrfDataUpdated[10212].value}
                        readOnly
                      />
                    </div>
                    <div className="fleft width15 textcenter"> &nbsp; </div>
                    <div className="fleft width15 textcenter">
                      <input
                        className="form_col width75"
                        type="text"
                        name=""
                        value={nplcCrfDataUpdated[10213].value}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="check over_hid pad_t7">
                    <div className="fleft width170px pad_t3">
                      {' '}
                      Managed Services Charges
                    </div>
                    <div className="fleft width7">&nbsp; </div>
                    <div className="fleft width7"> &nbsp;</div>
                    <div className="fleft width15 textcenter">
                      <input
                        className="form_col width75"
                        type="text"
                        name=""
                        value={nplcCrfDataUpdated[10214].value}
                        readOnly
                      />
                    </div>
                    <div className="fleft width15 textcenter">
                      <input
                        className="form_col width75"
                        type="text"
                        name=""
                        value={nplcCrfDataUpdated[10215].value}
                        readOnly
                      />
                    </div>
                    <div className="fleft width15 textcenter"> &nbsp; </div>
                    <div className="fleft width15 textcenter">
                      <input
                        className="form_col width75"
                        type="text"
                        name=""
                        value={nplcCrfDataUpdated[10216].value}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="check over_hid pad_t7">
                    <div className="fleft width170px pad_t3">
                      {' '}
                      Managed CPE Charges{' '}
                    </div>
                    <div className="fleft width7">&nbsp; </div>
                    <div className="fleft width7"> &nbsp;</div>
                    <div className="fleft width15 textcenter">
                      <input
                        className="form_col width75"
                        type="text"
                        name=""
                        value={nplcCrfDataUpdated[10217].value}
                        readOnly
                      />
                    </div>
                    <div className="fleft width15 textcenter">
                      <input
                        className="form_col width75"
                        type="text"
                        name=""
                        value={nplcCrfDataUpdated[10218].value}
                        readOnly
                      />
                    </div>
                    <div className="fleft width15 textcenter"> &nbsp; </div>
                    <div className="fleft width15 textcenter">
                      <input
                        className="form_col width75"
                        type="text"
                        name=""
                        value={nplcCrfDataUpdated[10219].value}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="check over_hid pad_t7">
                    <div className="fleft width170px pad_t3"> CPE Rentals </div>
                    <div className="fleft width7">&nbsp; </div>
                    <div className="fleft width7"> &nbsp;</div>
                    <div className="fleft width15 textcenter">
                      <input
                        className="form_col width75"
                        type="text"
                        name=""
                        value={nplcCrfDataUpdated[10220].value}
                        readOnly
                      />
                    </div>
                    <div className="fleft width15 textcenter">
                      <input
                        className="form_col width75"
                        type="text"
                        name=""
                        value={nplcCrfDataUpdated[10221].value}
                        readOnly
                      />
                    </div>
                    <div className="fleft width15 textcenter">
                      <input
                        className="form_col width75"
                        type="text"
                        name=""
                        value={nplcCrfDataUpdated[10222].value}
                        readOnly
                      />
                    </div>
                    <div className="fleft width15 textcenter">
                      <input
                        className="form_col width75"
                        type="text"
                        name=""
                        value={nplcCrfDataUpdated[10223].value}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="check over_hid pad_t7">
                    <div className="fleft width170px pad_t3"> </div>
                    <div className="fleft pad_t5 pad_r5">CPE 1 </div>
                    <div className="fleft" style={{ width: '9.5%' }}>
                      {' '}
                      &nbsp;
                    </div>
                    <div className="fleft width15 textcenter">
                      <input
                        className="form_col width75"
                        type="text"
                        name=""
                        value={nplcCrfDataUpdated[10224].value}
                        readOnly
                      />
                    </div>
                    <div className="fleft width15 textcenter">
                      <input
                        className="form_col width75"
                        type="text"
                        name=""
                        value={nplcCrfDataUpdated[10225].value}
                        readOnly
                      />
                    </div>
                    <div className="fleft width15 textcenter">
                      <input
                        className="form_col width75"
                        type="text"
                        name=""
                        value={nplcCrfDataUpdated[10226].value}
                        readOnly
                      />
                    </div>
                    <div className="fleft width15 textcenter">
                      <input
                        className="form_col width75"
                        type="text"
                        name=""
                        value={nplcCrfDataUpdated[10227].value}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="check over_hid pad_t7">
                    <div className="fleft width170px pad_t3"> </div>
                    <div className="fleft pad_t5 pad_r5">CPE 2 </div>
                    <div className="fleft" style={{ width: '9.5%' }}>
                      {' '}
                      &nbsp;
                    </div>
                    <div className="fleft width15 textcenter">
                      <input
                        className="form_col width75"
                        type="text"
                        name=""
                        value={nplcCrfDataUpdated[10228].value}
                        readOnly
                      />
                    </div>
                    <div className="fleft width15 textcenter">
                      <input
                        className="form_col width75"
                        type="text"
                        name=""
                        value={nplcCrfDataUpdated[10229].value}
                        readOnly
                      />
                    </div>
                    <div className="fleft width15 textcenter">
                      <input
                        className="form_col width75"
                        type="text"
                        name=""
                        value={nplcCrfDataUpdated[10230].value}
                        readOnly
                      />
                    </div>
                    <div className="fleft width15 textcenter">
                      <input
                        className="form_col width75"
                        type="text"
                        name=""
                        value={nplcCrfDataUpdated[10231].value}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="check over_hid pad_t7">
                    <div className="fleft width170px pad_t3"> </div>
                    <div className="fleft pad_t5 pad_r5">CPE 3</div>
                    <div className="fleft" style={{ width: '9.5%' }}>
                      {' '}
                      &nbsp;
                    </div>
                    <div className="fleft width15 textcenter">
                      <input
                        className="form_col width75"
                        type="text"
                        name=""
                        value={nplcCrfDataUpdated[10232].value}
                        readOnly
                      />
                    </div>
                    <div className="fleft width15 textcenter">
                      <input
                        className="form_col width75"
                        type="text"
                        name=""
                        value={nplcCrfDataUpdated[10233].value}
                        readOnly
                      />
                    </div>
                    <div className="fleft width15 textcenter">
                      <input
                        className="form_col width75"
                        type="text"
                        name=""
                        value={nplcCrfDataUpdated[10234].value}
                        readOnly
                      />
                    </div>
                    <div className="fleft width15 textcenter">
                      <input
                        className="form_col width75"
                        type="text"
                        name=""
                        value={nplcCrfDataUpdated[10235].value}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="check over_hid pad_t7">
                    <div className="fleft width170px pad_t3"> </div>
                    <div className="fleft pad_t5 pad_r5">CPE 4 </div>
                    <div className="fleft" style={{ width: '9.5%' }}>
                      {' '}
                      &nbsp;
                    </div>
                    <div className="fleft width15 textcenter">
                      <input
                        className="form_col width75"
                        type="text"
                        name=""
                        value={nplcCrfDataUpdated[10236].value}
                        readOnly
                      />
                    </div>
                    <div className="fleft width15 textcenter">
                      <input
                        className="form_col width75"
                        type="text"
                        name=""
                        value={nplcCrfDataUpdated[10237].value}
                        readOnly
                      />
                    </div>
                    <div className="fleft width15 textcenter">
                      <input
                        className="form_col width75"
                        type="text"
                        name=""
                        value={nplcCrfDataUpdated[10238].value}
                        readOnly
                      />
                    </div>
                    <div className="fleft width15 textcenter">
                      <input
                        className="form_col width75"
                        type="text"
                        name=""
                        value={nplcCrfDataUpdated[10239].value}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="check over_hid pad_t7">
                    <div className="fleft width170px pad_t3"> </div>
                    <div className="fleft pad_t5 pad_r5">CPE 5 </div>
                    <div className="fleft" style={{ width: '9.5%' }}>
                      {' '}
                      &nbsp;
                    </div>
                    <div className="fleft width15 textcenter">
                      <input
                        className="form_col width75"
                        type="text"
                        name=""
                        value={nplcCrfDataUpdated[10240].value}
                        readOnly
                      />
                    </div>
                    <div className="fleft width15 textcenter">
                      <input
                        className="form_col width75"
                        type="text"
                        name=""
                        value={nplcCrfDataUpdated[10241].value}
                        readOnly
                      />
                    </div>
                    <div className="fleft width15 textcenter">
                      <input
                        className="form_col width75"
                        type="text"
                        name=""
                        value={nplcCrfDataUpdated[10242].value}
                        readOnly
                      />
                    </div>
                    <div className="fleft width15 textcenter">
                      <input
                        className="form_col width75"
                        type="text"
                        name=""
                        value={nplcCrfDataUpdated[10243].value}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="check over_hid pad_t7">
                    <div className="fleft width170px pad_t3">
                      Miscellaneous Charges - 1{' '}
                    </div>
                    <div className="fleft width7 pad_t5"> &nbsp; </div>
                    <div className="fleft width7"> &nbsp;</div>
                    <div className="fleft width15 textcenter">
                      <input
                        className="form_col width75"
                        type="text"
                        name=""
                        value={nplcCrfDataUpdated[10244].value}
                        readOnly
                      />
                    </div>
                    <div className="fleft width15 textcenter">
                      <input
                        className="form_col width75"
                        type="text"
                        name=""
                        value={nplcCrfDataUpdated[10245].value}
                        readOnly
                      />
                    </div>
                    <div className="fleft width15 textcenter">&nbsp;</div>
                    <div className="fleft width15 textcenter">
                      <input
                        className="form_col width75"
                        type="text"
                        name=""
                        value={nplcCrfDataUpdated[10246].value}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="check over_hid pad_t7">
                    <div className="fleft width170px pad_t3">
                      Miscellaneous Charges - 2{' '}
                    </div>
                    <div className="fleft width7 pad_t5"> &nbsp; </div>
                    <div className="fleft width7"> &nbsp;</div>
                    <div className="fleft width15 textcenter">
                      <input
                        className="form_col width75"
                        type="text"
                        name=""
                        value={nplcCrfDataUpdated[10247].value}
                        readOnly
                      />
                    </div>
                    <div className="fleft width15 textcenter">
                      <input
                        className="form_col width75"
                        type="text"
                        name=""
                        value={nplcCrfDataUpdated[10248].value}
                        readOnly
                      />
                    </div>
                    <div className="fleft width15 textcenter"> &nbsp; </div>
                    <div className="fleft width15 textcenter">
                      <input
                        className="form_col width75"
                        type="text"
                        name=""
                        value={nplcCrfDataUpdated[10249].value}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="check over_hid pad_t7">
                    <div className="fleft width170px pad_t3">
                      {' '}
                      Shifting Charges{' '}
                    </div>
                    <div className="fleft width7 pad_t5"> &nbsp; </div>
                    <div className="fleft width7"> &nbsp;</div>
                    <div className="fleft width15 textcenter"> &nbsp; </div>
                    <div className="fleft width15 textcenter">
                      <input
                        className="form_col width75"
                        type="text"
                        name=""
                        value={nplcCrfDataUpdated[10250].value}
                        readOnly
                      />
                    </div>
                    <div className="fleft width15 textcenter"> &nbsp; </div>
                    <div className="fleft width15 textcenter">
                      <input
                        className="form_col width75"
                        type="text"
                        name=""
                        value={nplcCrfDataUpdated[10251].value}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="check over_hid pad_t7">
                    <div className="fleft width170px pad_t3">
                      {' '}
                      CPE Replacement Charges
                    </div>
                    <div className="fleft width7 pad_t5"> &nbsp; </div>
                    <div className="fleft width7"> &nbsp;</div>
                    <div className="fleft width15 textcenter"> &nbsp; </div>
                    <div className="fleft width15 textcenter">
                      <input
                        className="form_col width75"
                        type="text"
                        name=""
                        value={nplcCrfDataUpdated[10252].value}
                        readOnly
                      />
                    </div>
                    <div className="fleft width15 textcenter"> &nbsp; </div>
                    <div className="fleft width15 textcenter">
                      <input
                        className="form_col width75"
                        type="text"
                        name=""
                        value={nplcCrfDataUpdated[10253].value}
                        readOnly
                      />
                    </div>
                  </div>
                </div>
                <p>
                  <strong>** Taxes as applicable.</strong>
                </p>
                <br />
                <br />
                <br />
                <br />
                ...
                <br />
                <br />
                <div className="signate float_img">
                  {firstCaps !== '' ? (
                    <div style={{ padding: '10px' }} className={Signcss}>
                      {firstCaps + '  .  '}
                      <span className="under_line">{secondBold}</span>
                    </div>
                  ) : (
                    ''
                  )}
                  {imageData !== null && imageData !== '' ? (
                    <div style={{ padding: '10px' }} className="signate_img">
                      <img
                        src={
                          imageData != null
                            ? 'data:' +
                              imageType +
                              ';base64,' +
                              encode(imageData)
                            : ''
                        }
                        alt={'Company Seal'}
                      />
                    </div>
                  ) : (
                    ''
                  )}
                </div>
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
              </div>
            </div>
          </page>

          <page size="A4">
            <div className="section2">
              <div className="font8">
                <p className="textcenter font15 margin0 pad_t10 pad_b5 hed5">
                  COMMENTS
                </p>
                <div className="cmt_spl">
                  {' '}
                  Comments/ Special Considerations:{' '}
                  {nplcCrfDataUpdated[10255].value}
                </div>
                <div className="over_hid">
                  <div className="fleft width65 pad_t10">
                    <p className="margin0">
                      <strong>Declaration</strong>
                    </p>
                    <p className="margin0">
                      I / We confirm having received, read and understood the
                      Product Terms & Conditions (provided overleaf) and the
                      General Terms & Conditions. I / We further confirm that
                      the tariff plan selected and applicable rates form part of
                      this Agreement (as defined herein) and I / We agree to
                      abide by the Applicable Law in force and also any
                      statutory amendments, or new legistlations as may be
                      enacted from time to time, in so far as they realte to the
                      services. I / We hereby declare and confirm that the above
                      information provided by us is true and correct in all
                      respects and I / We hereby undertake to be bound by the
                      same.{' '}
                    </p>
                    <p className="margin0 pad_5t">
                      Authorised Signatory's Name
                    </p>
                    <p className="margin0">
                      <input
                        className="form_col width98per cmnt_input"
                        type="text"
                        name=""
                        value={nplcCrfDataUpdated[10096].value}
                        readOnly
                      />
                    </p>
                    <div className="over_hid pad_t15">
                      <div className="fleft width30">
                        <p className="margin0">Designation</p>
                        <p className="margin0">
                          <input
                            className="form_col widthfull cmnt_input"
                            type="text"
                            name=""
                            value={nplcCrfDataUpdated[10258].value}
                            readOnly
                          />
                        </p>
                      </div>
                      <div className="fleft width30 mar_l20">
                        <p className="margin0">Place</p>
                        <p className="margin0">
                          <input
                            className="form_col widthfull cmnt_input"
                            type="text"
                            name=""
                            value={nplcCrfDataUpdated[10259].value}
                            readOnly
                          />
                        </p>
                      </div>
                      <div className="fleft width30 mar_l20">
                        <p className="margin0">Date</p>
                        <p className="margin0">
                          <input
                            className="form_col width96per cmnt_input"
                            type="text"
                            name=""
                            value={nplcCrfDataUpdated[10260].value}
                            readOnly
                          />
                        </p>
                      </div>
                    </div>
                    <p className="margin0 pad_5t">Account Manager Name</p>
                    <div className="signate float_img">
                      {imageData !== null && imageData !== '' ? (
                        <div
                          style={{ padding: '10px' }}
                          className="signate_img"
                        >
                          <img
                            src={
                              imageData != null
                                ? 'data:' +
                                  imageType +
                                  ';base64,' +
                                  encode(imageData)
                                : ''
                            }
                            alt={'Company Seal'}
                          />
                        </div>
                      ) : (
                        ''
                      )}
                    </div>
                    <p className="margin0">
                      <input
                        className="form_col width98per cmnt_input"
                        type="text"
                        name=""
                        value={nplcCrfDataUpdated[10262].value}
                        readOnly
                      />
                    </p>
                    <p className="margin0 pad_20t">
                      Account Manager Circle Office
                    </p>
                    <p className="margin0">
                      <input
                        className="form_col  wid120p cmnt_input"
                        type="text"
                        name=""
                        value={nplcCrfDataUpdated[10263].value}
                        readOnly
                      />
                    </p>
                    <div className="over_hid pad_20t">
                      <div className="fleft width50">
                        <p className="margin0">Channel Partner Name</p>
                        <p className="margin0">
                          <input
                            className="form_col widthfull cmnt_input"
                            type="text"
                            name=""
                            value={nplcCrfDataUpdated[10265].value}
                            readOnly
                          />
                        </p>
                      </div>
                      <div className="flright width30">
                        <p className="margin0">Channel Partner Code</p>
                        <p className="margin0">
                          <input
                            className="form_col width92per cmnt_input"
                            type="text"
                            name=""
                            value={nplcCrfDataUpdated[10266].value}
                            readOnly
                          />
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="flright width30">
                    <div
                      style={{
                        padding: '5px',
                        lineHeight: 'normal',
                        width: '214px',
                      }}
                      className="signate"
                    >
                      {firstCaps !== '' ? (
                        <div className={Signcss}>
                          {firstCaps + '  .  '}
                          <span className="under_line">{secondBold}</span>
                        </div>
                      ) : (
                        ''
                      )}
                      <div>
                        Signature of the Customer / Authorised signatory with in
                        this box only (In case of institutinal, please affix
                        office/company seal){' '}
                      </div>
                    </div>
                    <div
                      style={{
                        padding: '5px',
                        height: '120px',
                        marginTop: '140px',
                        width: '216px',
                        alignItems: 'center',
                        display: 'flex',
                      }}
                      className="signate"
                    >
                      {acctMngSignImageData !== null &&
                      acctMngSignImageData !== '' ? (
                        <img
                          height="90px"
                          width="100px"
                          src={
                            acctMngSignImageData !== null
                              ? 'data:' +
                                acctMngSignImageType +
                                ';base64,' +
                                encode(acctMngSignImageData)
                              : ''
                          }
                          alt={'Signature of Account Manager'}
                        />
                      ) : (
                        ''
                      )}
                      <div> Signature of the Account Manager </div>
                    </div>
                  </div>
                </div>
                <p className="textcenter">
                  <strong>
                    Vodafone Idea Limited (formerly Idea Cellular Limited) An
                    Aditya Birla Group and Vodafone Partnership Merger Co CIN
                    No. L32100GJ1996PLC030976Registered Office: Suman Tower,
                    Plot No. 18 Sector 11, Gandhinagar-382011, Gujarat.
                  </strong>
                </p>
              </div>
            </div>
          </page>
          {excelJson !== null ? (
            <page size="A4">
              <div>
                <div className="pad_t10 section2">
                  <p className="customfom">Annexure to NPLC CRF :- </p>
                </div>
                <JsonToTable json={excelJson} />
                <div>
                  <p class="font10 " style={{ marginTop: '20px' }}>
                    <strong>Note:</strong> Address proofs will be required for
                    each of the sites listed above as part of standard
                    documentation guidelines; Master CAF referred here should be
                    signed by the same
                  </p>
                </div>

                <div class="fleft width50 pad_t10">
                  <p class="marb5">Account Manager Name</p>
                  <p class="margin0">
                    <input
                      class="form_col widthfull inputext"
                      type="text"
                      name="Account Manager Name"
                      readonly=""
                      value={nplcCrfDataUpdated[10262].value}
                    />
                  </p>
                  <div className="signate" style={{ position: 'relative' }}>
                    <div className="signate_img input_sign">
                      {acctMngSignImageData !== null &&
                      acctMngSignImageData !== '' ? (
                        <img
                          src={
                            acctMngSignImageData != null
                              ? 'data:' +
                                acctMngSignImageType +
                                ';base64,' +
                                encode(acctMngSignImageData)
                              : ''
                          }
                          alt={'Account Manager Sign'}
                        />
                      ) : (
                        ''
                      )}
                    </div>
                    <div>Signature of the Account Manager </div>
                  </div>
                </div>
                <div
                  className="signate float_img"
                  style={{ position: 'initial' }}
                >
                  <div className="over_hid ">
                    <div className="over_hid ">
                      Authorised Signatory Name
                      {
                        <input
                          className="form_col inputext"
                          type="text"
                          name="Name_CD"
                          value={nplcCrfDataUpdated[10096].value}
                          readOnly
                        />
                      }
                    </div>
                  </div>
                  {firstCaps !== '' ? (
                    <div style={{ padding: '10px' }} className={Signcss}>
                      {firstCaps + '  .  '}
                      <span className="under_line">{secondBold}</span>
                    </div>
                  ) : (
                    ''
                  )}
                  {imageData !== null && imageData !=='' ? (
                    <div style={{ padding: '10px' }} className="signate_img">
                      {
                        <img
                          src={
                            imageData != null
                              ? 'data:' +
                                imageType +
                                ';base64,' +
                                encode(imageData)
                              : ''
                          }
                          alt={'Company Seal'}
                        />
                      }
                    </div>
                  ) : (
                    ''
                  )}
                </div>
              </div>
            </page>
          ) : (
            ''
          )}
        </div>
      </div>
    );
  } else {
    NplcCrfContentView = CrfData ? (
      <div>
        <Center p={2} my={1}>
          <Spinner
            thickness="4px"
            speed="0.65s"
            emptyColor={chakraTheme.colors.secondary}
            color={chakraTheme.colors.brand[800]}
            size="md"
          />
        </Center>
      </div>
    ) : (
      <div style={{ marginTop: '200px', height: '300px', textAlign: 'center' }}>
        {' '}
        CRF Form not submitted yet.
      </div>
    );
  }
  return <div>{NplcCrfContentView}</div>;
};

export default NPLCCRFForm;
